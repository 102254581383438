import theme from "@/styles/theme";
import styled from "@emotion/styled";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { Box, Button, Grid } from "@mui/material";

export const TrialsGridWrapper = styled(Grid)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: auto;
    ${theme.breakpoints.only("xs")} {
      width: 100%;
      height: 100dvh;
      border-radius: 0;
    }

    ${theme.breakpoints.up("sm")} {
      width: ${(p) => p.theme.spacing(62)};
      border-radius: ${(p) => p.theme.spacing(2)};
    }
  }
`;

export const TrialsClosed = styled(Button)`
  && {
    position: absolute;
    top: ${(p) => p.theme.spacing(2)};
    right: ${(p) => p.theme.spacing(2)};
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    min-width: 0;

    &:hover {
      background: none;
    }
  }
`;

export const TrialsContentContainer = styled(Box)`
  && {
    width: 100%;
    padding: ${(p) => p.theme.spacing(5, 3, 3, 3)};
  }
`;

export const TrialsIconContainer = styled(Box)`
  && {
    width: 56px;
    height: 56px;
    border: 2px solid #925eff;
    border-radius: ${(p) => p.theme.spacing(1.5)};
    background: ${(p) => p.theme.palette.black[900]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TrialsIcon = styled(PersonRoundedIcon)`
  && {
    color: #925eff;
    width: 24px;
    height: 24px;
  }
`;

export const TrialsHeaderStyled = styled(Box)`
  && {
    text-align: center;
    margin-top: ${(p) => p.theme.spacing(3)};
  }
`;

export const TrialsFeaturesContainer = styled(Box)`
  && {
    width: 100%;
    padding: ${(p) => p.theme.spacing(3)};
    margin-top: ${(p) => p.theme.spacing(3)};
    background: ${(p) => p.theme.palette.grey[100]};
    border-radius: ${(p) => p.theme.spacing(2)};
  }
`;

export const TrialsFeaturesStyled = styled(Grid)`
  && {
    margin-top: ${(p) => p.theme.spacing(1)};
    align-items: flex-start;
    flex-wrap: nowrap;
  }
`;

export const TrialsCtaContainer = styled(Box)`
  && {
    width: 100%;
    margin-top: ${(p) => p.theme.spacing(0.5)};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const TrialsCtaButton = styled(Button)`
  && {
    width: 100%;
    background: ${(p) => p.theme.palette.black[900]};
    color: #ffffff;
  }
`;

export const TrialsNoCreditCard = styled(Grid)`
  && {
    justify-content: center;
    align-items: center;
    margin-top: ${(p) => p.theme.spacing(2)};
  }
`;

export const TrialsContainer = styled(Grid)`
  && {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;
