"use client";

import { FC, ReactElement, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { Theme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectReceiverConfirmedOrLoading } from "@/store/slices/receiver.slice";
import { withTransientProps } from "@/styles/theme";
import styled from "@emotion/styled";
import {
  logout,
  requestUserInfo,
  selectUserEmail,
  setLoginSuccess,
  selectUserPayment,
  selectUserPlan,
  setIsTrialsModalRequired,
  setShowTrialsModal,
} from "@/store/slices/auth.slice";
import {
  isAuthenticated,
  isRefreshRequired,
  setAuthenticationInfo,
} from "@/auth/auth";
import { heapRemoveEventProperty } from "@/common/utils/heapTracking";
import LogInModal from "@/components/login/LogInModal";
import {
  setAxiosBaseUrl,
  setupAxiosInterceptor,
} from "@/common/utils/setupAxios";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
import TrialsModal from "@/components/shared/trials/TrialsModal";
import routes from "@/common/constants/routes";
import {
  selectBasicTransfer,
  setBasicTransfer,
} from "@/store/slices/workspace.slice";
import { navigateTo } from "@/common/utils/navigation";
import { fetchErrorHandler } from "@/common/utils/nextErrorHandler";

const BackgroundContainer = dynamic(
  () => import("@/components/shared/BackgroundContainer"),
);
const Header = dynamic(() => import("@/components/shared/headers/Header"));
const PeachLogo = dynamic(() => import("@/components/shared/logos/PeachLogo"));
interface Props {
  children: ReactElement;
}

const bgColor = (
  theme: Theme,
  route: string,
  path: string,
  isReceiverConfirmed: boolean,
) => {
  if (path?.includes("/user/receiver")) {
    if (path?.includes("/files/") && isReceiverConfirmed) return "#FFF";
    if (isReceiverConfirmed) return "#F3F5F7";

    return "#F2F2F2";
  }

  if (path?.includes("/project/")) {
    return theme.palette.background.paper;
  }

  const mapper = {
    [routes.HOME]: "#ffffff",
    [routes.ERROR]: "#EDEEEB",
    [routes.REGISTER]: "#1A1A1A",
    [routes.REGISTER_VERIFY]: "#FFF",
    [routes.REGISTER_SUCCESS]: "#FFF",
    [routes.REGISTER_EXPIRED]: "#FFF",
    [routes.LOGIN_RESET_PASSWORD]: "#FFF",
    [routes.LOGIN_RESET_PASSWORD_SENT]: "#FFF",
    [routes.LOGIN_RESET_PASSWORD_SUCCESS]: "#FFF",
    [routes.LOGIN_RESET_PASSWORD_EXPIRED]: "#FFF",
    [routes.LOGIN_RESET_PASSWORD_RESET]: "#FFF",
    [routes.ACCOUNT]: "#FFF",
    [routes.ACCOUNT_RESET_PASSWORD_SENT]: "#FFF",
    [routes.ACCOUNT_PREFERENCES]: "#FFF",
    [routes.CHECKOUT]: "#FFF",
    [routes.CHECKOUT_SUCCESS]: "#eee2fb",
    [routes.UPLOAD]: "#F3F5F7",
    [routes.UPLOAD_ANALYZE]: "#F3F5F7",
    [routes.SUCCESS]: "#F2F2F2",
    [routes.EXPIRED]: "#F2F2F2",
    [routes.PRICING]: "#F2F2F2",
    [routes.VIRUS_DETECTED]: "#F3F5F7",
    [routes.WORKSPACES]: theme.palette.background.paper,
  };

  return mapper[route as keyof typeof mapper] || "#F2F2F2";
};

interface ContainerProps {
  $route: string;
  $path: string;
  $isReceiverConfirmed: boolean;
}

export const Container = styled("div", withTransientProps)<ContainerProps>`
  background-color: ${(p) =>
    bgColor(p.theme, p.$route, p.$path, p.$isReceiverConfirmed)};
  height: 100dvh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  display: flex;
`;

const PAGES_WITHOUT_PEACH_LOGO = [
  "/404",
  routes.HOME,
  routes.UPLOAD,
  routes.REGISTER_SUCCESS,
  routes.REGISTER_EXPIRED,
  routes.LOGIN_RESET_PASSWORD,
  routes.LOGIN_RESET_PASSWORD_SENT,
  routes.LOGIN_RESET_PASSWORD_SUCCESS,
  routes.LOGIN_RESET_PASSWORD_RESET,
  routes.LOGIN_RESET_PASSWORD_EXPIRED,
  routes.ACCOUNT,
  routes.ACCOUNT_RESET_PASSWORD_SENT,
  routes.VIRUS_DETECTED,
  routes.ERROR,
  routes.WORKSPACES,
  routes.RECEIVED_TRANSFERS,
  routes.CHECKOUT,
  routes.CHECKOUT_SUCCESS,
];

const PAGES_WITHOUT_HEADER = ["/404", routes.ERROR, routes.WORKSPACES];
const EXCLUDED_FILE_PREVIEW = "files";

interface UserPlan {
  planName: string;
}

interface UserPayment {
  customerKey: string;
}

const LayoutAppRouter: FC<Props> = ({ children }) => {
  const pathname = usePathname() || "";
  const dispatch = useAppDispatch();
  const plan = useAppSelector(selectUserPlan) as UserPlan;
  const payment = useAppSelector(selectUserPayment) as UserPayment;
  const basicTransfer = useAppSelector(selectBasicTransfer);
  const [appReady, setAppReady] = useState(false);
  const isReceiverConfirmedOrLoading = useAppSelector(
    selectReceiverConfirmedOrLoading,
  );

  const userEmail = useAppSelector(selectUserEmail);

  const isPreviewFilePage = pathname
    .split(routes.HOME)
    .includes(EXCLUDED_FILE_PREVIEW);

  const isHeaderHidden =
    PAGES_WITHOUT_HEADER.includes(pathname) ||
    isPreviewFilePage ||
    pathname.includes("/project/") ||
    pathname.includes(routes.RECEIVED_TRANSFERS);
  const isPeachLogoHidden =
    PAGES_WITHOUT_PEACH_LOGO.includes(pathname) ||
    isPreviewFilePage ||
    pathname.includes(routes.WORKSPACES) ||
    pathname.includes(routes.RECEIVED_TRANSFERS);

  const hasRun = useRef(false);

  const showTrialsModalActive = () => {
    if (plan?.planName === "registered-user" && payment?.customerKey) {
      // User is on the "Basic" plan but has completed or canceled their trial
      dispatch(setShowTrialsModal(false));
      // Trial/upgrade modal is required for locked features
      dispatch(setIsTrialsModalRequired(true));
    } else if (plan?.planName === "registered-user") {
      // User is on the "Basic" plan and has not started a trial
      dispatch(setShowTrialsModal(true));
      dispatch(setIsTrialsModalRequired(true));
    } else if (plan?.planName !== "registered-user" && payment?.customerKey) {
      // User is (possibly trialing) on a paid plan
      dispatch(setShowTrialsModal(false));
      dispatch(setIsTrialsModalRequired(false));
    }

    return;
  };

  useEffect(() => {
    if (pathname.includes("/project")) return;

    if (basicTransfer) {
      dispatch(setBasicTransfer(false));
    }
  }, [pathname]);

  useEffect(() => {
    showTrialsModalActive();
  }, [plan, payment, pathname]);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    (async () => {
      if (isRefreshRequired() && isAuthenticated()) {
        try {
          const response = await fetch(`/api/token_refresh`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          });

          const responseData = await response.json();

          if (!response.ok) {
            throw fetchErrorHandler(responseData);
          }

          await setAuthenticationInfo(responseData);
          setAppReady(true);
        } catch {
          localStorage.removeItem("auth");
          dispatch(logout());
          document.cookie = "vwo_logged_in=false";
          return navigateTo(routes.HOME);
        }
        await dispatch(requestUserInfo());
      } else if (!userEmail && isAuthenticated() && !isRefreshRequired()) {
        setAppReady(true);
        await dispatch(requestUserInfo());
      } else {
        setAppReady(true);
      }
    })();
  }, [userEmail]);

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(setLoginSuccess());
    } else {
      dispatch(logout());
      document.cookie = "vwo_logged_in=false";
      heapRemoveEventProperty("logged_in");
    }
  }, []);

  useEffect(() => {
    if (ENV_CONSTANTS.IS_SPEEDCURVE_ENABLED) {
      LUX.init();
      LUX.send();
    }
  }, [pathname]);

  const dispatchLogout = () => {
    document.cookie = "vwo_logged_in=false";
    dispatch(logout());
  };

  setAxiosBaseUrl();

  useEffect(() => {
    if (!appReady) return;
    setupAxiosInterceptor(dispatchLogout);
  }, [appReady]);

  return (
    <Container
      $route={pathname}
      $path={pathname}
      $isReceiverConfirmed={isReceiverConfirmedOrLoading}
    >
      {appReady && (
        <>
          <BackgroundContainer>
            {!isHeaderHidden && <Header />}
            {children}
            {!isPeachLogoHidden && <PeachLogo />}
          </BackgroundContainer>
          <LogInModal />
          <TrialsModal />
        </>
      )}
    </Container>
  );
};

export default LayoutAppRouter;
