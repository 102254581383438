import theme from "@/styles/theme";
import { Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { TrialsHeaderStyled } from "@/components/shared/trials/trials.styles";
import Link from "next/link";
import routes from "@/common/constants/routes";

interface TrialsHeaderProps {
  isSender: boolean;
  isUpgrade: boolean;
}

export const TrialsHeader = ({ isSender, isUpgrade }: TrialsHeaderProps) => {
  const { t } = useTranslation("trials");

  const hedaerTextReceiver =
    isUpgrade && !isSender
      ? "trials.modal.trial.heading.upgrade"
      : "trials.modal.trial.heading.receiver";
  const hedaerTextSender =
    isUpgrade && isSender
      ? "trials.modal.trial.heading.upgrade"
      : "trials.modal.trial.heading.sender";

  return (
    <>
      {isSender ? (
        <TrialsHeaderStyled>
          <Typography variant={isUpgrade ? "hM" : "hL"}>
            {t(`${hedaerTextSender}`)}
          </Typography>
          <Typography
            variant="bodyL"
            sx={{
              marginTop: theme.spacing(1),
              padding: theme.spacing(0, 4),
              color: theme.palette.black[600],
            }}
          >
            <Trans t={t}>
              {isUpgrade
                ? "trials.modal.trial.sub.heading.upgrade"
                : "trials.modal.trial.sub.heading.sender"}

              <Link
                href={routes.PRICING}
                data-testid="trials-modal-solo-link"
                data-analytics="trials-modal-solo-link"
                style={{
                  textDecoration: "underline",
                }}
              >
                link
              </Link>
            </Trans>
          </Typography>
        </TrialsHeaderStyled>
      ) : (
        <TrialsHeaderStyled>
          <Typography variant="hM" sx={{ padding: theme.spacing(0, 3) }}>
            {t(`${hedaerTextReceiver}`)}
          </Typography>
          <Typography
            variant="bodyL"
            sx={{
              marginTop: theme.spacing(1),
              padding: theme.spacing(0, 4),
              color: theme.palette.black[600],
            }}
          >
            <Trans t={t}>
              {isUpgrade
                ? "trials.modal.trial.sub.heading.upgrade"
                : "trials.modal.trial.sub.heading.receiver"}
              <Link
                href={routes.PRICING}
                data-testid="trials-modal-solo-link"
                data-analytics="trials-modal-solo-link"
                style={{
                  textDecoration: "underline",
                }}
              >
                link
              </Link>
            </Trans>
          </Typography>
        </TrialsHeaderStyled>
      )}
    </>
  );
};
