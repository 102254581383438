"use client";

import { useAppDispatch, useAppSelector } from "@/store";
import {
  selectIsShowLoginModal,
  setShowLoginModal,
} from "@/store/slices/auth.slice";
import theme from "@/styles/theme";
import { Box, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Modal } from "@mui/material";
import Image from "next/image";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "next-i18next";
import {
  LogInGridWrapper,
  MarketingLink,
  MarketingWrapper,
} from "@/components/login/login.styles";
import dynamic from "next/dynamic";
import { selectReceiverSessionInfo } from "@/store/slices/receiver.slice";
import routes from "@/common/constants/routes";
import { usePathname, useRouter } from "next/navigation";
import setCurrentUrl from "@/common/utils/setCurrentUrl";

const LoginForm = dynamic(() => import("@/components/login/LoginForm"));
const SignUpForm = dynamic(() => import("@/components/signUpForm/SignUpForm"));

const LogInModal = () => {
  const { t } = useTranslation("login");
  const dispatch = useAppDispatch();
  const isShowLoginModal = useAppSelector(selectIsShowLoginModal);
  const [open, setOpen] = useState(false);
  const sessionInfo = useAppSelector(selectReceiverSessionInfo);
  const router = useRouter();
  const [hasAccount, setHasAccount] = useState("false");
  const pathName = usePathname();

  useEffect(() => {
    setHasAccount(localStorage.getItem("hasAccount") || "");
  });

  const onSuccessSignUp = (email: string) => {
    const query = new URLSearchParams({ email }).toString();
    setCurrentUrl(pathName);

    if (isShowLoginModal) {
      dispatch(setShowLoginModal(false));
    }

    router.push(`/register/verify?${query}`);
  };

  useEffect(() => {
    setOpen(isShowLoginModal);
  }, [isShowLoginModal]);

  return (
    <Modal open={open} data-testid="dti-login-modal" disableAutoFocus>
      <LogInGridWrapper container $hasAccount={hasAccount}>
        <Grid
          item
          md={5.37}
          sx={{
            display: { xs: "none", md: "flex" },
          }}
          data-testid="dti-marketing-stuff"
        >
          <MarketingWrapper>
            <Box sx={{ position: "relative" }} width="100%" height="100%">
              <Image
                src="/img/Analysing.svg"
                alt=""
                width={450}
                height={500}
                priority={true}
                style={{
                  height: "auto",
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  bottom: theme.spacing(-10),
                }}
              />
            </Box>
            <Box
              sx={{ padding: theme.spacing(2, 4) }}
              position="absolute"
              top={theme.spacing(4)}
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <Image
                src="/img/peachGoLogoWhite.svg"
                width={110}
                height={21}
                alt="Peach logo"
              />
              <Typography variant="hM" sx={{ color: "#E1E1E1" }}>
                {t("login.modal.marketing.headline")}
              </Typography>
              <Typography
                variant="bodyM"
                sx={{ color: "#B5B5B5", wordBreak: "break-all" }}
              >
                {t("login.modal.marketing.subheadline.one", {
                  email: sessionInfo?.senderEmail,
                  files: sessionInfo?.numberOfFiles,
                })}
              </Typography>

              <Typography variant="bodyM" sx={{ color: "#B5B5B5" }}>
                {t("login.modal.marketing.subheadline.two")}
              </Typography>

              <Typography variant="bodyM" sx={{ color: "#B5B5B5" }}>
                <Trans t={t}>
                  login.modal.marketing.subheadline.three
                  <MarketingLink
                    href={routes.HOME}
                    onClick={() => dispatch(setShowLoginModal(false))}
                  >
                    link
                  </MarketingLink>
                </Trans>
              </Typography>
            </Box>
          </MarketingWrapper>
        </Grid>
        <Grid item xs={12} md={6.63} data-testid="dti-login-form">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            sx={{
              padding: {
                xs: theme.spacing(0, 3, 3, 3),
                md: theme.spacing(2, 3, 3, 3),
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              sx={{
                height: {
                  xs: theme.spacing(7),
                  md: theme.spacing(4),
                },
                marginRight: {
                  xs: theme.spacing(-1),
                  md: 0,
                },
              }}
            />
            <Box display="flex" flex={1}>
              {hasAccount ? (
                <LoginForm type="receiver" />
              ) : (
                <SignUpForm
                  onSuccess={(email: string) => onSuccessSignUp(email)}
                  type="receiver"
                />
              )}
            </Box>
          </Box>
        </Grid>
      </LogInGridWrapper>
    </Modal>
  );
};

export default LogInModal;
